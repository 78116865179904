<template>
  <div class="thank-you-page">
    <PageBanner
      background="main"
      heading="Beställning genomförd"
      class="thank-you-page-banner" />
    <PageBody class="col-sm-12 col-md-6 thank-you-page__body">
      <img
        alt="completed"
        src="@/assets/svg/thumb_up.svg"
        width="52">
      <p class="thank-you-page__text">
        Din beställning av <b>{{ selectedOffer.friendlyName }}</b> är nu skickad till leverantören. Tack för din beställning.
      </p>
      <br>
      <a @click="goToLandingPage">
        TIll startsidan
      </a>
    </PageBody>
  </div>
</template>

<script>
import PageBanner from '@/components/views/PageBanner'
import PageBody from '@/components/views/PageBody'
import { RouteName } from '@/router/router-constants'
import { mapState } from 'vuex'

export default {
  components: {
    PageBanner,
    PageBody
  },
  computed: {
    ...mapState('AvailableOffers', ['selectedOffer'])
  },
  methods: {
    goToLandingPage () {
      this.$router.push({ name: RouteName.closed.LandingPage })
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you-page {

  &__body {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  &__text {
    margin-top: 30px;
  }
}
</style>
